@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto'),
         local('Roboto-Regular'),
         url('/assets/fonts/roboto.woff2') format('woff2'), /* Super Modern Browsers */
         url('/assets/fonts/roboto.woff') format('woff'), /* Modern Browsers */
         url('/assets/fonts/Roboto-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
  }
  
  body {
    font-family: Roboto;
  }
