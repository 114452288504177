pre.highlight {
    padding: 1rem;
}

pre .hll { background-color: #ffffcc }
pre  { background: #f0f0f0; }
pre .c { color: #60a0b0; font-style: italic } /* Comment */
pre .err { border: 1px solid #FF0000 } /* Error */
pre .k { color: #007020; font-weight: bold } /* Keyword */
pre .o { color: #666666 } /* Operator */
pre .ch { color: #60a0b0; font-style: italic } /* Comment.Hashbang */
pre .cm { color: #60a0b0; font-style: italic } /* Comment.Multiline */
pre .cp { color: #007020 } /* Comment.Preproc */
pre .cpf { color: #60a0b0; font-style: italic } /* Comment.PreprocFile */
pre .c1 { color: #60a0b0; font-style: italic } /* Comment.Single */
pre .cs { color: #60a0b0; background-color: #fff0f0 } /* Comment.Special */
pre .gd { color: #A00000 } /* Generic.Deleted */
pre .ge { font-style: italic } /* Generic.Emph */
pre .gr { color: #FF0000 } /* Generic.Error */
pre .gh { color: #000080; font-weight: bold } /* Generic.Heading */
pre .gi { color: #00A000 } /* Generic.Inserted */
pre .go { color: #888888 } /* Generic.Output */
pre .gp { color: #c65d09; font-weight: bold } /* Generic.Prompt */
pre .gs { font-weight: bold } /* Generic.Strong */
pre .gu { color: #800080; font-weight: bold } /* Generic.Subheading */
pre .gt { color: #0044DD } /* Generic.Traceback */
pre .kc { color: #007020; font-weight: bold } /* Keyword.Constant */
pre .kd { color: #007020; font-weight: bold } /* Keyword.Declaration */
pre .kn { color: #007020; font-weight: bold } /* Keyword.Namespace */
pre .kp { color: #007020 } /* Keyword.Pseudo */
pre .kr { color: #007020; font-weight: bold } /* Keyword.Reserved */
pre .kt { color: #902000 } /* Keyword.Type */
pre .m { color: #40a070 } /* Literal.Number */
pre .s { color: #4070a0 } /* Literal.String */
pre .na { color: #4070a0 } /* Name.Attribute */
pre .nb { color: #007020 } /* Name.Builtin */
pre .nc { color: #0e84b5; font-weight: bold } /* Name.Class */
pre .no { color: #60add5 } /* Name.Constant */
pre .nd { color: #555555; font-weight: bold } /* Name.Decorator */
pre .ni { color: #d55537; font-weight: bold } /* Name.Entity */
pre .ne { color: #007020 } /* Name.Exception */
pre .nf { color: #06287e } /* Name.Function */
pre .nl { color: #002070; font-weight: bold } /* Name.Label */
pre .nn { color: #0e84b5; font-weight: bold } /* Name.Namespace */
pre .nt { color: #062873; font-weight: bold } /* Name.Tag */
pre .nv { color: #bb60d5 } /* Name.Variable */
pre .ow { color: #007020; font-weight: bold } /* Operator.Word */
pre .w { color: #bbbbbb } /* Text.Whitespace */
pre .mb { color: #40a070 } /* Literal.Number.Bin */
pre .mf { color: #40a070 } /* Literal.Number.Float */
pre .mh { color: #40a070 } /* Literal.Number.Hex */
pre .mi { color: #40a070 } /* Literal.Number.Integer */
pre .mo { color: #40a070 } /* Literal.Number.Oct */
pre .sa { color: #4070a0 } /* Literal.String.Affix */
pre .sb { color: #4070a0 } /* Literal.String.Backtick */
pre .sc { color: #4070a0 } /* Literal.String.Char */
pre .dl { color: #4070a0 } /* Literal.String.Delimiter */
pre .sd { color: #4070a0; font-style: italic } /* Literal.String.Doc */
pre .s2 { color: #4070a0 } /* Literal.String.Double */
pre .se { color: #4070a0; font-weight: bold } /* Literal.String.Escape */
pre .sh { color: #4070a0 } /* Literal.String.Heredoc */
pre .si { color: #70a0d0; font-style: italic } /* Literal.String.Interpol */
pre .sx { color: #c65d09 } /* Literal.String.Other */
pre .sr { color: #235388 } /* Literal.String.Regex */
pre .s1 { color: #4070a0 } /* Literal.String.Single */
pre .ss { color: #517918 } /* Literal.String.Symbol */
pre .bp { color: #007020 } /* Name.Builtin.Pseudo */
pre .fm { color: #06287e } /* Name.Function.Magic */
pre .vc { color: #bb60d5 } /* Name.Variable.Class */
pre .vg { color: #bb60d5 } /* Name.Variable.Global */
pre .vi { color: #bb60d5 } /* Name.Variable.Instance */
pre .vm { color: #bb60d5 } /* Name.Variable.Magic */
pre .il { color: #40a070 } /* Literal.Number.Integer.Long */.highlight pre .hll { background-color: #ffffcc }
.highlight pre  { background: #f0f3f3; }
.highlight pre .c { color: #0099FF; font-style: italic } /* Comment */
.highlight pre .err { color: #AA0000; background-color: #FFAAAA } /* Error */
.highlight pre .k { color: #006699; font-weight: bold } /* Keyword */
.highlight pre .o { color: #555555 } /* Operator */
.highlight pre .ch { color: #0099FF; font-style: italic } /* Comment.Hashbang */
.highlight pre .cm { color: #0099FF; font-style: italic } /* Comment.Multiline */
.highlight pre .cp { color: #009999 } /* Comment.Preproc */
.highlight pre .cpf { color: #0099FF; font-style: italic } /* Comment.PreprocFile */
.highlight pre .c1 { color: #0099FF; font-style: italic } /* Comment.Single */
.highlight pre .cs { color: #0099FF; font-weight: bold; font-style: italic } /* Comment.Special */
.highlight pre .gd { background-color: #FFCCCC; border: 1px solid #CC0000 } /* Generic.Deleted */
.highlight pre .ge { font-style: italic } /* Generic.Emph */
.highlight pre .gr { color: #FF0000 } /* Generic.Error */
.highlight pre .gh { color: #003300; font-weight: bold } /* Generic.Heading */
.highlight pre .gi { background-color: #CCFFCC; border: 1px solid #00CC00 } /* Generic.Inserted */
.highlight pre .go { color: #AAAAAA } /* Generic.Output */
.highlight pre .gp { color: #000099; font-weight: bold } /* Generic.Prompt */
.highlight pre .gs { font-weight: bold } /* Generic.Strong */
.highlight pre .gu { color: #003300; font-weight: bold } /* Generic.Subheading */
.highlight pre .gt { color: #99CC66 } /* Generic.Traceback */
.highlight pre .kc { color: #006699; font-weight: bold } /* Keyword.Constant */
.highlight pre .kd { color: #006699; font-weight: bold } /* Keyword.Declaration */
.highlight pre .kn { color: #006699; font-weight: bold } /* Keyword.Namespace */
.highlight pre .kp { color: #006699 } /* Keyword.Pseudo */
.highlight pre .kr { color: #006699; font-weight: bold } /* Keyword.Reserved */
.highlight pre .kt { color: #007788; font-weight: bold } /* Keyword.Type */
.highlight pre .m { color: #FF6600 } /* Literal.Number */
.highlight pre .s { color: #CC3300 } /* Literal.String */
.highlight pre .na { color: #330099 } /* Name.Attribute */
.highlight pre .nb { color: #336666 } /* Name.Builtin */
.highlight pre .nc { color: #00AA88; font-weight: bold } /* Name.Class */
.highlight pre .no { color: #336600 } /* Name.Constant */
.highlight pre .nd { color: #9999FF } /* Name.Decorator */
.highlight pre .ni { color: #999999; font-weight: bold } /* Name.Entity */
.highlight pre .ne { color: #CC0000; font-weight: bold } /* Name.Exception */
.highlight pre .nf { color: #CC00FF } /* Name.Function */
.highlight pre .nl { color: #9999FF } /* Name.Label */
.highlight pre .nn { color: #00CCFF; font-weight: bold } /* Name.Namespace */
.highlight pre .nt { color: #330099; font-weight: bold } /* Name.Tag */
.highlight pre .nv { color: #003333 } /* Name.Variable */
.highlight pre .ow { color: #000000; font-weight: bold } /* Operator.Word */
.highlight pre .w { color: #bbbbbb } /* Text.Whitespace */
.highlight pre .mb { color: #FF6600 } /* Literal.Number.Bin */
.highlight pre .mf { color: #FF6600 } /* Literal.Number.Float */
.highlight pre .mh { color: #FF6600 } /* Literal.Number.Hex */
.highlight pre .mi { color: #FF6600 } /* Literal.Number.Integer */
.highlight pre .mo { color: #FF6600 } /* Literal.Number.Oct */
.highlight pre .sa { color: #CC3300 } /* Literal.String.Affix */
.highlight pre .sb { color: #CC3300 } /* Literal.String.Backtick */
.highlight pre .sc { color: #CC3300 } /* Literal.String.Char */
.highlight pre .dl { color: #CC3300 } /* Literal.String.Delimiter */
.highlight pre .sd { color: #CC3300; font-style: italic } /* Literal.String.Doc */
.highlight pre .s2 { color: #CC3300 } /* Literal.String.Double */
.highlight pre .se { color: #CC3300; font-weight: bold } /* Literal.String.Escape */
.highlight pre .sh { color: #CC3300 } /* Literal.String.Heredoc */
.highlight pre .si { color: #AA0000 } /* Literal.String.Interpol */
.highlight pre .sx { color: #CC3300 } /* Literal.String.Other */
.highlight pre .sr { color: #33AAAA } /* Literal.String.Regex */
.highlight pre .s1 { color: #CC3300 } /* Literal.String.Single */
.highlight pre .ss { color: #FFCC33 } /* Literal.String.Symbol */
.highlight pre .bp { color: #336666 } /* Name.Builtin.Pseudo */
.highlight pre .fm { color: #CC00FF } /* Name.Function.Magic */
.highlight pre .vc { color: #003333 } /* Name.Variable.Class */
.highlight pre .vg { color: #003333 } /* Name.Variable.Global */
.highlight pre .vi { color: #003333 } /* Name.Variable.Instance */
.highlight pre .vm { color: #003333 } /* Name.Variable.Magic */
.highlight pre .il { color: #FF6600 } /* Literal.Number.Integer.Long */
